import React from "react";
import { QRCodeCanvas } from "qrcode.react";

const DisplayQRCode = ({ icon, appDetail, title, onClose, url }) => {
  return (
    <div>
      <QRCodeCanvas
        value={appDetail.url}
        imageSettings={{
          src: appDetail.icon,
          x: undefined,
          y: undefined,
          height: 25,
          width: 25,
          excavate: true
        }}
      />
    </div>
  );
};

export default DisplayQRCode;