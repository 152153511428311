import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import "./ConfirmDelete.css";
import axios from "axios";

const ConfirmDelete = ({ record, onClose }) => {
  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [deletedMessage, setDeletedMessage] = useState("");

  const handleCodeChange = (e) => {
    setCode(e.target.value);
    setError(null);
  };

  const handleDelete = async () => {
    try {
      if (code !== "30") {
        setError("Incorrect code. Please try again.");
        return;
      }

      // Delete DNS record
      await deleteDNSRecord(record.dnsRecordId);

      // Delete record from subdomains table
      await deleteRecordFromSubdomains(record.subdomain);

      // Delete record from subdomainfiles table
      await deleteRecordFromSubdomainFiles(record.subdomain);

      await deleteSubdomainNginxConfigFile(record.subdomain);

      setSuccess(true);
      setDeletedMessage(`DNS Record with ID ${record.id} deleted successfully!`);
    } catch (error) {
      setError(error.message || "An error occurred.");
    }
  };

  const deleteDNSRecord = async (dnsRecordId) => {
    try {
      const response = await axios.delete(
        `https://ads-txt.in/api/dns-records/${dnsRecordId}`
      );
      console.log("DNS Record Deleted:", response.data);
    } catch (error) {
      console.log("Error deleting the DNS Record", error);
      throw error.response.data || error.message;
    }
  };

  const deleteRecordFromSubdomains = async (subdomain) => {
    try {
      const response = await axios.delete(
        `https://ads-txt.in/api/subdomains-table/${subdomain}`
      );
      console.log(
        `Deleted record from subdomains table: ${subdomain}`,
        response.data
      );
    } catch (error) {
      console.log("Error deleting the record from subdomains table", error);
      throw error.response.data || error.message;
    }
  };

  const deleteRecordFromSubdomainFiles = async (subdomain) => {
    try {
      const response = await axios.delete(
        `https://ads-txt.in/api/subdomainfiles-table/${subdomain}`
      );
      console.log(
        `Deleted record from subdomainfiles table: ${subdomain}`,
        response.data
      );
    } catch (error) {
      console.log("Error deleting the record from subdomainfiles table", error);
      throw error.response.data || error.message;
    }
  };

  const deleteSubdomainNginxConfigFile = async (subdomain) => {
    try {
      const response = await axios.delete(
        `https://ads-txt.in/api/subdomain-nginx-file/${subdomain}`
      );
      console.log(`Deleted ${subdomain}.conf file`, response.data);
    } catch(error) {
      console.log("Error deleting the subdomain conf file", error);
      throw error.response.data || error.message;
    }
  };
  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div className="delete-form">
            <h1 className="text-2xl font-bold">Enter Code to delete Record</h1>
            <input
              type="text"
              placeholder="Code"
              value={code}
              onChange={handleCodeChange}
              required
            />
            {error && <Alert severity="error">{error}</Alert>}
            {success && (
              <>
                <Alert severity="success">Record deleted successfully!</Alert>
                {deletedMessage && (
                  <Alert severity="info">{deletedMessage}</Alert>
                )}
              </>
            )}
            <button
              type="button"
              onClick={handleDelete}
              className={`text-green-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-800 ${
                window.innerWidth <= 615
                  ? "text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 dark:text-white"
                  : ""
              }`}
            >
              Confirm
            </button>
            <button
              type="button"
              onClick={onClose}
              className={`text-green-700 hover:text-white border border-gray-700 hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-gray-500 dark:text-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 ${
                window.innerWidth <= 615
                  ? "text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900 dark:text-white"
                  : ""
              }`}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmDelete;
