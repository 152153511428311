import React, { useState, useEffect } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import "./UpdateTextFile.css";

const UpdateTextFile = ({
  onClose,
  subdomain,
  fileName,
  fileContent,
  onUpdateSuccess
}) => {


  const [updatedSubdomain, setupdatedSubdomain] = useState(subdomain);
  const [updatedFileName, setUpdatedFileName] = useState(fileName);
  const [updatedFileContent, setUpdatedFileContent] = useState(fileContent);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");


  useEffect(() => {
    console.log("useEffect for fileName triggered:", fileName);
    setUpdatedFileName(fileName);
  }, [fileName]);

  useEffect(() => {
    console.log("useEffect for fileContent triggered:", fileContent);
    setUpdatedFileContent(fileContent);
  }, [fileContent]);


  const handleUpdateFile = async () => {
    try {
      if (!updatedFileName || !updatedFileContent) {
        setAlertMessage("Please enter both file name and content");
        setAlertSeverity("error");
        return;
      }

      await axios.put(`https://ads-txt.in/api/update-file`, {
        updatedFileName,
        content: updatedFileContent,
        fileName: encodeURIComponent(fileName)
      });

      setAlertMessage("File updated successfully");
      setAlertSeverity("success");

      onUpdateSuccess({
        fileName: updatedFileName,
        fileContent: updatedFileContent
      });

      setUpdatedFileContent(updatedFileContent);
    } catch (error) {
      console.error("Error updating file:", error);
      setAlertMessage("Error updating file");
      setAlertSeverity("error");
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  const handleCloseModal = () => {
    onClose();
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div className="update-form">
            <input
              type="text"
              placeholder="File Name"
              value={updatedFileName}
              onChange={(e) => setUpdatedFileName(e.target.value)}
              required
            />
            <textarea
              placeholder="File Content"
              value={updatedFileContent}
              onChange={(e) => setUpdatedFileContent(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={handleUpdateFile}
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              Update
            </button>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
            >
              Close
            </button>
          </div>
          {alertMessage && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={handleCloseAlert}
            >
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateTextFile;
