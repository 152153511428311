import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { fetchAppDetails } from "../../reducers/appDetailsReducer";
import { getAppDetail } from "../routes/web-render.route";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./WebPageRender.css";
import "./DarkModeStyle.css";
import DarkModeTheme from "../DarkMode/DarkModeTheme";
import DarkModeToggle from "../DarkMode/DarkModeToggle";
import Sliders from "../Sliders/Sliders";
import ShareLink from "../ShareLink/ShareLink";
import Loader from "../Loader/Loader";
import { PiDownloadSimpleBold } from "react-icons/pi";
import { IoIosStar } from "react-icons/io";
import { AiFillMail } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { MdOutlinePolicy } from "react-icons/md";
import { FaShareAlt } from "react-icons/fa";
import { IoIosArrowUp } from "react-icons/io";
import DisplayQRCode from "../DisplayQRCode/DisplayQRCode";

const WebPageRender = ({ adSupported, sublink }) => {
  const { subDomain } = useParams();
  const dispatch = useDispatch();
  const jsonData = useSelector((state) => state.appDetails.data);
  const [loading, setLoading] = useState(true);
  const [isDark, setIsDark] = useState(false);
  const [appDetail, setAppDetail] = useState({});
  const [showShareLink, setShowShareLink] = useState(false);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({
    width: 0,
    height: 0
  });

  useEffect(() => {
    document.title = appDetail.title || "React App";
    document.querySelector('link[rel="icon"]').href =
      appDetail.icon || "%PUBLIC_URL%/favicon.ico";
  }, [appDetail]);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowScrollToTop(true);
      } else {
        setShowScrollToTop(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getSubDomainFromUrl = () => {
    const hostname = window.location.hostname;
    // Define a regular expression to match subdomains
    const regex = /^(.*?)\.ads-txt/;

    // Use the regular expression to extract the subdomain
    const match = hostname.match(regex);

    // Check if a match is found
    if (match && match.length > 1) {
      // Return the first captured group, which is the subdomain
      return match[1];
    }

    // If no match is found, return null
    return null;
  };

  useEffect(() => {
    const subDomain = getSubDomainFromUrl();
    if (subDomain) {
      // Dispatch the fetchAppDetails action with the subDomain parameter
      dispatch(fetchAppDetails(subDomain));
    } else {
      console.error("Subdomain not found in URL");
    }
  }, [dispatch]);

  const formatNumber = (num) => {
    if (!num) return;
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + "B+";
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + "M+";
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + "K+";
    } else {
      return num?.toString() + "+";
    }
  };
  const formatInstalls = (installs) => {
    if (!installs) return;
    const installsNum = parseInt(installs.replace(/,/g, ""));

    if (installs.includes("B")) {
      return formatNumber(installsNum * 1e9);
    } else if (installs.includes("M")) {
      return formatNumber(installsNum * 1e6);
    } else {
      return formatNumber(installsNum);
    }
  };

  const filterScreenshotsBySize = (screenshots, width, height) => {
    if (!screenshots && !screenshots?.length) return;
    return screenshots.filter((screenshot) => {
      const img = new Image();
      img.src = screenshot;
      return img.width === width && img.height === height;
    });
  };
  const getPhoneScreenshots = () => {
    if (
      !appDetail ||
      !appDetail?.screenshots ||
      appDetail?.screenshots.length === 0
    ) {
      return [];
    }

    const phoneWidth = 288;
    const phoneHeight = 512;

    const filteredScreenshots = filterScreenshotsBySize(
      appDetail.screenshots,
      phoneWidth,
      phoneHeight
    );
    return filteredScreenshots.length > 0
      ? filteredScreenshots
      : appDetail.screenshots;
  };
  const handleShareButtonClick = () => {
    setShowShareLink(true);
  };

  const handleCloseShareLink = () => {
    setShowShareLink(false);
  };
  const phoneScreenshots = getPhoneScreenshots();
  useEffect(() => {
    if (
      appDetail &&
      appDetail?.screenshots &&
      appDetail?.screenshots?.length > 0
    ) {
      const firstImage = new Image();
      firstImage.src = appDetail.screenshots[0];

      firstImage.onload = () => {
        setImageDimensions({
          width: firstImage.width,
          height: firstImage.height
        });
      };
    }
  }, [appDetail]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const domainsub = getSubDomainFromUrl();
        setLoading(true);

        const data = await getAppDetail(domainsub);
        setAppDetail(data);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching app details:", error);
        setLoading(false);
      }
    };
    fetchData();
  }, [dispatch, subDomain, sublink]);

  if (loading) {
    return <Loader />;
  }

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowScrollToTop(false);
  };

  return (
    <body>
      {/* <DarkModeToggle isDarkMode={isDarkMode} onChange={toggleDarkMode} /> */}
      <div className="w-full bg-[#FFF] py-20">
        <div className="md:max-w-[1480px] m-auto grid md:grid-cols-2 gap-8 max-w-[600px] items-center  px-4 md:px-0">
          <div>
            <h1 className="md:leading-[72px] py-2 md:text-6xl text-5xl font-semibold">
              <span className="text-[#000]">{appDetail.title}</span>
            </h1>
            <p className="py-1 text-lg text-gray-600">{appDetail.developer}</p>
            <div className="py-4 flex items-center">
              <div className="p-2 bg-[#fff] rounded-xl">
                <IoIosStar size={30} style={{ color: "#1A906B" }} />
              </div>
              <div className="px-2">
                <h1 className="text-xl font-semibold">{appDetail.scoreText}</h1>
                <p className="text-[#6D737A] mt-1">
                  {formatNumber(appDetail.ratings)} reviews
                </p>
              </div>
              <div className="p-2 bg-[#fff] rounded-xl">
                <PiDownloadSimpleBold size={30} style={{ color: "#1A906B" }} />
              </div>
              <div className="px-2">
                <h1 className="text-xl font-semibold">
                  {formatInstalls(appDetail.installs)}
                </h1>
                <p className="text-[#6D737A] mt-1">Downloads</p>
              </div>
            </div>
            <button
              className="flex items-center px-8 py-3 rounded-lg bg-[#20B486] text-white font-bold"
              onClick={handleShareButtonClick}
            >
              <span>Share</span>
              <span className="ml-1">
                <FaShareAlt />
              </span>
            </button>
          </div>
          <img
            src={appDetail.icon}
            className="w-[250px] h-[250px] mx-auto rounded-3xl py-[-2] px-[-1] drop-shadow-2xl"
            alt="Iconimage"
          />
        </div>
        <Sliders phoneScreenshots={phoneScreenshots} />
      </div>

      {showShareLink && (
        <ShareLink
          icon={appDetail.icon}
          appDetail={appDetail}
          title={appDetail.title}
          onClose={handleCloseShareLink}
        />
      )}

      <div className="w-full bg-white py-8">
        <div className="md:max-w-[1480px] m-auto grid md:grid-cols-2 max-w-[600px]  px-4 md:px-0">
          <div className="flex flex-col justify-center items-center ">
            <h1 className="md:leading-[72px] text-3xl font-bold">
              ABOUT THIS<span className="text-[#20B486]"> APP</span>
            </h1>
            <br />
            <p
              className="text-lg text-gray-600"
              style={{ textAlign: "justify" }}
            >
              {appDetail.description}
            </p>
          </div>
          <div className="flex flex-col justify-center items-center">
            <h1 className="md:leading-[72px] text-3xl font-bold">
              APP <span className="text-[#20B486]">&nbsp;SUPPORT</span>
            </h1>
            <div className="py-6">
              <div className="flex items-center mb-4">
                <div className="p-4 bg-[#E9F8F3] rounded-xl mr-4 flex items-center">
                  <AiFillMail size={30} style={{ color: "#1A906B" }} />
                </div>
                <div>
                  <a
                    href="mailto:updown.an@gmail.com"
                    target="_blank"
                    rel="noreferrer"
                    className="text-xl font-semibold mb-0"
                    style={{ marginBottom: 0 }} // <-- Added inline style here
                  >
                    <span>Support Email</span>
                    <br />
                    <span className="text-[#6D737A]">
                      {appDetail.developerEmail}
                    </span>
                  </a>
                </div>
              </div>

              {appDetail.developerAddress && (
                <div className="flex items-center mb-4">
                  <div className="p-4 bg-[#FFFAF5] rounded-xl mr-4 flex items-center">
                    <MdLocationOn size={30} style={{ color: "#FFC27A" }} />
                  </div>
                  <div>
                    <p className="font-semibold mb-0">
                      <span className="text-[#000] text-2xl font-bold">
                        Address
                      </span>
                      <br />
                      <a
                        href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
                          appDetail.developerAddress
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-[#6D737A]"
                      >
                        {appDetail.developerAddress}
                      </a>
                    </p>
                  </div>
                </div>
              )}
              <div className="flex items-center">
                <div className="p-4 bg-[#fddfde] rounded-xl mr-4 flex items-center">
                  <MdOutlinePolicy size={30} style={{ color: "#ED4459" }} />
                </div>
                <div>
                  <a
                    href={appDetail.privacyPolicy}
                    rel="noreferrer"
                    target="_blank"
                    className="text-2xl font-semibold mb-0"
                    style={{ marginBottom: 0 }}
                  >
                    <span>Privacy Policy</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="qr">
        <div class="qr-content">
          <DisplayQRCode appDetail={appDetail} />
          <p>Scan To Download App</p>
        </div>
      </div>

      {showScrollToTop && (
        <button className="scroll-to-top" onClick={scrollToTop} style={{"display" : "flex"}}>
          <IoIosArrowUp />
        </button>
      )}
    </body>
  );
};

export default WebPageRender;
