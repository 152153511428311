import React, { useState } from "react";

import "./ShareLink.css";

const ShareLink = ({ icon, appDetail, title, onClose, url }) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    const textField = document.createElement("textarea");
    textField.innerText = appDetail.url;
    document.body.appendChild(textField);
    textField.select();
    document.execCommand("copy");
    textField.remove();

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div
            jsslot=""
            className="VfPpkd-cnG4Wd snipcss-bjgrg"
            jsname="rZHESd"
          >
            <div className="LUq2je">
              <div className="efXJRc">
                <img
                  src={appDetail.icon}
                  className="T75of PT2eod"
                  aria-hidden="true"
                  alt="Icon image"
                  itemprop="image"
                  data-iml="6448965.7"
                />
                <div>
                  <h5 className="ksLHF">{appDetail.title}</h5>
                  <div className="cYHJbc">Share this app</div>
                </div>
              </div>
            </div>
          </div>
          <div className="copy-container">
            <div className="copy-text">
              <input
                type="text"
                className="text"
                value={appDetail.url}
                readOnly
              />
              <button onClick={handleCopy}>
                <span className="material-icons copy-icon">content_copy</span>
              </button>
            </div>
            {isCopied && (
              <div className="tooltip">
                <span className="tooltiptext">Copied!</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareLink;
