import React, { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import "./UpdateAllTextFile.css";

const UpdateAllTextFile = ({ onClose }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [newContent, setNewContent] = useState(""); // State to store the new content

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  const handleUpdateAll = async () => {
    try {
      if (!newContent) {
        setAlertMessage("Please enter new content");
        setAlertSeverity("error");
        return;
      }

      // Send a request to update all files with the new content
      await axios.put("https://ads-txt.in/api/update-all-files", {
        content: newContent
      });

      setAlertMessage("All files updated successfully");
      setAlertSeverity("success");
    } catch (error) {
      console.error("Error updating all files:", error);
      setAlertMessage("Error updating all files");
      setAlertSeverity("error");
    }
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div className="update-all-form">
            <textarea
              placeholder="Enter new content for all files"
              value={newContent}
              onChange={(e) => setNewContent(e.target.value)}
              required
            />
            <button
              type="button"
              className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              onClick={handleUpdateAll}
            >
              Confirm
            </button>
            <button
              type="button"
              className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              onClick={onClose}
            >
              Close
            </button>
          </div>
          {alertMessage && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={handleCloseAlert}
            >
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpdateAllTextFile;
