import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = {
  data: null,
  status: "idle",
  error: null
};

export const fetchAppDetails = createAsyncThunk(
  "appDetails/fetchAppDetails",
  async (subDomain, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `https://ads-txt.in/app-detail/${subDomain}`
      );
      const data = await response.data;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const appDetailsSlice = createSlice({
  name: "appDetails",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAppDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchAppDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.data = action.payload;
      })
      .addCase(fetchAppDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  }
});

export default appDetailsSlice.reducer;