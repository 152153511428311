import React, { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import "./DeleteFileModal.css";

const DeleteFileModal = ({ onClose, fileContent, fileName }) => {
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [textToDelete, setTextToDelete] = useState("");

  const handleDeleteText = async () => {
    try {
      if (!textToDelete) {
        setAlertMessage("Please enter text to delete.");
        setAlertSeverity("error");
        return;
      }

      await axios.post(
        `https://ads-txt.in/api/delete-text-from-file/${fileName}`,
        { textToDelete }
      );

      setAlertMessage("Entered Text Deleted Successfully");
      setAlertSeverity("success");
    } catch (error) {
      console.error("Error deleting text:", error);
      setAlertMessage("Error deleting text. Please try again.");
      setAlertSeverity("error");
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };
  return (
    <div className="delete-overlay">
      <div className="delete-box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div className="delete-content">
            <div className="file-content" style={{ whiteSpace: "pre-wrap" }}>
              {fileContent}
            </div>
            <div className="delete-form">
              <textarea
                placeholder="Enter text which you want to delete from file"
                value={textToDelete}
                onChange={(e) => setTextToDelete(e.target.value)}
                required
              />
              <button
                type="button"
                onClick={handleDeleteText}
                className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
              >
                Confirm
              </button>
              <button
                type="button"
                onClick={onClose}
                className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
              >
                Close
              </button>
            </div>
            {alertMessage && (
              <Alert
                variant="filled"
                severity={alertSeverity}
                onClose={handleCloseAlert}
              >
                {alertMessage}
              </Alert>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteFileModal;
