import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import Alert from "@mui/material/Alert";
import UpdateTextFile from "../UpdateTextFile/UpdateTextFile";
import UpdateAllTextFile from "../UpdateAllTextFile/UpdateAllTextFile";
import DeleteFileModal from "../DeleteFileModal/DeleteFileModal";
import DeleteAllFileModal from "../DeleteAllFileModal/DeleteAllFileModal";
import "./CreateTextFile.css";

const CreateTextFile = ({
  onClose,
  onCreateSuccess,
  subdomain,
  updateDropdownAfterDelete
}) => {
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [updateFileData, setUpdateFileData] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [showUpdateAllModal, setShowUpdateAllModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDeleteAllModal, setShowDeleteAllModal] = useState(false);
  const [selectedFileContent, setSelectedFileContent] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [highlightedFile, setHighlightedFile] = useState(null); // State to track highlighted file
  const location = useLocation();

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    if (highlightedFile) {
      const timer = setTimeout(() => {
        setHighlightedFile(null); // Remove highlight after 3 seconds
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [highlightedFile]);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(
        `https://ads-txt.in/api/files?subdomain=${subdomain}`
      );
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleUpdateFile = async (file) => {
    try {
      if (!file.content) {
        const response = await axios.post(`https://ads-txt.in/api/get-file`, {
          subdomain: file.subdomain,
          fileName: encodeURIComponent(file.fileName)
        });
        file.content = response.data.content;
      }
      setUpdateFileData({
        subdomain: file.subdomain,
        fileName: file.fileName,
        content: file.content
      });
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  const handleCloseUpdate = () => {
    setUpdateFileData(null);
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  const handleOpenUpdateAllClick = () => {
    setShowUpdateAllModal(true);
  };

  const handleOpenDeleteAllClick = () => {
    setShowDeleteAllModal(true);
  };

  const handleCreateFile = async () => {
    try {
      if (!fileName || !fileContent) {
        setAlertMessage("Please enter both file name and content");
        setAlertSeverity("error");
        return null;
      }

      await axios.post("https://ads-txt.in/api/create-file", {
        subdomain,
        fileName,
        content: fileContent
      });

      setAlertMessage(`${fileName}.txt File Created successfully`);
      setAlertSeverity("success");

      onCreateSuccess({ fileName, fileContent });
      fetchFiles();

      // Highlight the newly created file for 3 seconds
      setHighlightedFile(fileName);
    } catch (error) {
      console.error("Error creating file:", error);
    }
  };

  const handleDeleteFile = async (file) => {
    try {
      const response = await axios.post(`https://ads-txt.in/api/get-file`, {
        subdomain: file.subdomain,
        fileName: encodeURIComponent(file.fileName)
      });
      setSelectedFileContent(response.data.content);
      setSelectedFileName(file.fileName);
      setShowDeleteModal(true);
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          {!showCreateForm && (
            <div className="flex flex-row justify-center items-center space-x-4">
              <button
                className={`text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 ${
                  window.innerWidth <= 615
                    ? "text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900 dark:text-white"
                    : ""
                }`}
                onClick={() => setShowCreateForm(true)}
              >
                Create
              </button>
              <button
                className={`text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 ${
                  window.innerWidth <= 615
                    ? "text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900 dark:text-white"
                    : ""
                }`}
                onClick={() => handleOpenUpdateAllClick()}
              >
                Update All
              </button>
              <button
                className={`text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800 ${
                  window.innerWidth <= 615
                    ? "text-sm font-medium text-center text-white bg-gray-700 rounded-lg hover:bg-gray-800 focus:ring-4 focus:outline-none focus:ring-gray-300 dark:bg-gray-600 dark:hover:bg-gray-700 dark:focus:ring-gray-900 dark:text-white"
                    : ""
                }`}
                onClick={() => handleOpenDeleteAllClick()}
              >
                Delete All
              </button>
            </div>
          )}
          <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 create-table-container">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-start text-xs font-medium text-gray-100 uppercase"
                  >
                    File Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-center text-xs font-medium text-gray-100 uppercase"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {files.map((file, index) => (
                  <tr
                    key={index}
                    className={
                      highlightedFile === file.fileName ? "bg-green-100" : ""
                    }
                  >
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-800">
                      {file.fileName}
                    </td>
                    <td className="flex flex-row justify-center items-center space-x-4">
                      <button
                        className={`text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800 ${
                          window.innerWidth <= 615
                            ? "px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800 dark:text-white"
                            : ""
                        }`}
                        style={{
                          marginTop: "8px"
                        }}
                        onClick={() => handleUpdateFile(file)}
                      >
                        Update
                      </button>
                      <button
                        className={`text-red-700 hover:text-white border border-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-red-500 dark:text-red-500 dark:hover:text-white dark:hover:bg-red-600 dark:focus:ring-red-900 ${
                          window.innerWidth <= 615
                            ? "px-3 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900 dark:text-white"
                            : ""
                        }`}
                        style={{
                          marginTop: "8px"
                        }}
                        onClick={() => handleDeleteFile(file)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {alertMessage && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={handleCloseAlert}
            >
              {alertMessage}
            </Alert>
          )}
          {showCreateForm && (
            <div className="create-form">
              <input
                type="text"
                placeholder="File Name"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
                required
              />
              <textarea
                placeholder="File Content"
                value={fileContent}
                onChange={(e) => setFileContent(e.target.value)}
                required
              />

              <button
                type="button"
                onClick={handleCreateFile}
                className={`text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800 ${
                  window.innerWidth <= 615
                    ? "text-sm font-medium text-center text-white bg-green-700 rounded-lg hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-900 dark:text-white"
                    : ""
                }`}
              >
                Submit
              </button>
            </div>
          )}
          {updateFileData && (
            <UpdateTextFile
              onClose={handleCloseUpdate}
              subdomain={updateFileData.subdomain}
              fileName={updateFileData.fileName}
              fileContent={updateFileData.content}
              onUpdateSuccess={() => {
                fetchFiles();
              }}
            />
          )}
          {showUpdateAllModal && (
            <UpdateAllTextFile onClose={() => setShowUpdateAllModal(false)} />
          )}
          {showDeleteModal && (
            <DeleteFileModal
              onClose={() => setShowDeleteModal(false)}
              fileContent={selectedFileContent}
              fileName={selectedFileName}
            />
          )}
          {showDeleteAllModal && (
            <DeleteAllFileModal onClose={() => setShowDeleteAllModal(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateTextFile;
