import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { useStep } from "../../StepContext";
import Alert from "@mui/material/Alert";

const AddSubdomain = ({ currentStepCompleted }) => {
  const { currentStep, goToNextStep } = useStep();
  const location = useLocation();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const sublink = location.state?.sublink;

  const validationSchema = Yup.object({
    subdomain: Yup.string().required("Subdomain is required"),
    link: Yup.string().required("Link is required")
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    const { subdomain } = values;
    console.log(subdomain);
    try {
      const response = await axios.post("https://ads-txt.in/api/subdomain", {
        subdomain: subdomain,
        link: sublink
      });
      console.log(response.data);
      navigate("/add-app-ads-txt", { state: { subdomain: values.subdomain } });
      goToNextStep();
    } catch (error) {
      if (
        error.response?.status === 409 &&
        error.response.data === "Subdomain already exists"
      ) {
        setAlertMessage(
          "Subdomain Already Exist, Please Choose Another Subdomain Name"
        );
        setAlertSeverity("error");
        return null;
      } else {
        const errorMessageText = error.response?.data || error.message;
        if (typeof errorMessageText === "object") {
          setErrorMessage(JSON.stringify(errorMessageText));
        } else {
          setErrorMessage(errorMessageText);
        }
      }
    } finally {
      setSubmitting(false);
    }
  };
  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      {currentStep === 2 && (
        <>
          <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">
            Generate Subdomain
          </h1>
          <Formik
            initialValues={{ subdomain: "", link: sublink || "" }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid, isSubmitting }) => (
              <Form className="flex flex-col items-center">
                <div className="mt-8 mb-8 w-full sm:w-2/3">
                  <label
                    htmlFor="subdomain"
                    className={`block text-sm font-medium ${
                      window.innerWidth <= 615 ? "text-black" : "text-gray-700"
                    }`}
                  >
                    Enter the name of Subdomain that you want for your App
                  </label>
                  <Field
                    type="text"
                    id="subdomain"
                    name="subdomain"
                    className="mt-1 p-3 border rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="e.g. mysubdomain"
                  />
                  <ErrorMessage
                    name="subdomain"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                {alertMessage && (
                  <Alert
                    variant="filled"
                    severity={alertSeverity}
                    onClose={handleCloseAlert}
                  >
                    {alertMessage}
                  </Alert>
                )}

                <div className="flex justify-around mt-[10%] w-[88%]">
                  {/* <div className="text-gray-500 font-bold px-6 py-3 rounded ">
                    <Link to={"/"}>
                      Go Back
                    </Link>
                  </div> */}
                  <div className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                    <button
                      type="submit"
                      disabled={!dirty || !isValid || isSubmitting}
                    >
                      Next Step
                    </button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}

      {currentStep !== 2 && !currentStepCompleted && (
        <Alert severity="warning">
          Please complete the current step first.
        </Alert>
      )}
    </div>
  );
};

export default AddSubdomain;