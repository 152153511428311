import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "@material-tailwind/react";
import axios from "axios";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeveloperTxtFile from "../DeveloperTxtFile/DeveloperTxtFile";
import Loading from "../Loading/Loading";
import "./AccountDashboard.css";

const POLL_INTERVAL = 60000; // Polling interval in milliseconds (e.g., 1 minute)

const AccountDashboard = () => {
  const navigate = useNavigate();
  const [developers, setDevelopers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [fileContent, setFileContent] = useState("");
  const [showFileModal, setShowFileModal] = useState(false);
  const [selectedSubdomain, setSelectedSubdomain] = useState("");
  const [notLiveEmailSent, setNotLiveEmailSent] = useState(false);

  useEffect(() => {
    const fetchDevelopers = async () => {
      try {
        const response = await axios.get("https://ads-txt.in/api/developers");
        const allDevelopers = response.data;

        // Separate live and not live developers
        const liveApps = allDevelopers.filter((developer) => developer.developer !== "App is not Live");
        const notLiveApps = allDevelopers.filter((developer) => developer.developer === "App is not Live");

        // Update the developer list to display not live apps separately under their respective developer names
        const updatedDevelopers = liveApps.map((developer) => ({
          ...developer,
          records: developer.records.filter((record) => record.developer !== "App is not Live")
        }));

        setDevelopers([...updatedDevelopers, ...notLiveApps]);
        setLoading(false);

        // Check if any not live developer's app is not live and send email
        notLiveApps.forEach((developer) => {
          if (!notLiveEmailSent) {
            // sendNotLiveEmail(developer.developer);
            setNotLiveEmailSent(true); // Set flag to true once email is sent
          } 
        });
      } catch (error) {
        console.error("Error fetching developers:", error);
        setLoading(false);
      }
    };

    fetchDevelopers();

    // Start polling for updates
    const pollInterval = setInterval(fetchDevelopers, POLL_INTERVAL);

    // Clean up interval on component unmount
    return () => clearInterval(pollInterval);
  }, []);

  const handleOpenDeveloper = (developerRecords) => {
    navigate("/adminpanel", { state: { developerRecords } });
  };

  const handleFetchFileContent = async (subdomain) => {
    try {
      const response = await axios.post(
        "https://ads-txt.in/api/get-file-content",
        { subdomain }
      );
      setFileContent(response.data.fileContent);
      setShowFileModal(true);
      setSelectedSubdomain(subdomain);
    } catch (error) {
      console.error("Error fetching file content:", error);
    }
  };

  const handleCreate = () => {
    navigate("/addapp");
  };

  // const sendNotLiveEmail = async (developerName) => {
  //   try {
  //     await axios.post("https://ads-txt.in/api/sendNotLiveEmail", {
  //       recipientEmail: "sonigaurav6030@gmail.com",
  //       appName: developerName
  //     });
  //     console.log("Not Live email sent successfully");
  //   } catch (error) {
  //     console.error("Error sending not live email:", error);
  //   }
  // };

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newOrder = Array.from(developers);
    const [movedDeveloper] = newOrder.splice(result.source.index, 1);
    newOrder.splice(result.destination.index, 0, movedDeveloper);

    // Log newOrder array to check the structure and developer names
    console.log("New Order Array:", newOrder);

    // Extract only the IDs from newOrder for ordering
    const orderedDeveloperIds = newOrder.map((developer) => {
      const firstRecord = developer.records[0];
      return firstRecord && firstRecord.id ? firstRecord.id : null;
    }).filter(id => id !== null);

    console.log(orderedDeveloperIds, "orderedDeveloperIds");
    setDevelopers(newOrder);

    try {
      // Send the orderedDeveloperIds array as part of the request body
      await axios.post("https://ads-txt.in/api/update-developer-order", { order: orderedDeveloperIds });
    } catch (error) {
      console.error("Error updating developer order:", error);
    }
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);
  };

  let filteredDevelopers = Array.isArray(developers)
    ? developers.filter((developer) =>
        developer.developer.toLowerCase().includes(searchQuery)
      )
    : [];

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="developers">
            {(provided) => (
              <div
                className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 account-table-container"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                <div className="flex justify-center items-center mb-4">
                  <div className="ml-4 mt-4">
                    <Button
                      onClick={() => navigate("/add-app-ads-txt")}
                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                    >
                      Manage app-ads.txt
                    </Button>
                    <Button
                      onClick={handleCreate}
                      className="text-green-700 hover:text-white border border-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-green-500 dark:text-green-500 dark:hover:text-white dark:hover:bg-green-600 dark:focus:ring-green-800"
                    >
                      Create
                    </Button>
                    <input
                      type="text"
                      value={searchQuery}
                      onChange={handleSearchInputChange}
                      placeholder="Search developer..."
                      className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300"
                    />
                  </div>
                </div>

                {filteredDevelopers.length === 0 ? (
                  <Typography tag="p" className="text-lg font-bold mt-4">
                    No Developer Account Found.
                  </Typography>
                ) : (
                  <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start font-bold text-xl font-medium text-gray-100 uppercase"
                        >
                          Account
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-start font-bold text-xl font-medium text-gray-100 uppercase"
                        ></th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-center font-bold text-xl font-medium text-gray-100 uppercase"
                        >
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {filteredDevelopers.map(
                        ({ developer, records }, index) => {
                          const record = records[0];
                          const subdomain = record?.subdomain || "";
                          return (
                            <Draggable
                              key={index}
                              draggableId={`developer-${index}`}
                              index={index}
                            >
                              {(provided) => (
                                <tr
                                  key={index}
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-800">
                                    {developer}
                                  </td>
                                  <td>
                                    <button
                                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                      style={{
                                        marginTop: "8px"
                                      }}
                                      onClick={() =>
                                        handleFetchFileContent(subdomain)
                                      }
                                    >
                                      TXT File
                                    </button>
                                  </td>
                                  <td className="flex flex-row justify-center items-center space-x-4">
                                    <button
                                      className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
                                      style={{
                                        marginTop: "8px"
                                      }}
                                      onClick={() =>
                                        handleOpenDeveloper(records)
                                      }
                                    >
                                      Open
                                    </button>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          );
                        }
                      )}
                    </tbody>
                  </table>
                )}
                {provided.placeholder}
                {showFileModal && (
                  <DeveloperTxtFile
                    onClose={() => setShowFileModal(false)}
                    fileContent={fileContent}
                    subdomain={selectedSubdomain}
                  />
                )}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </>
  );
};

export default AccountDashboard;
