import React, { useState, useEffect } from "react";
import { useLocation, Navigate, Link } from "react-router-dom";
import { useStep } from "../../StepContext";
import Alert from "@mui/material/Alert";
import axios from "axios";
import "./Final.css";
import { IoChevronBackCircleSharp } from "react-icons/io5";

const Final = () => {
  const { currentStep } = useStep();
  const location = useLocation();
  const subdomain = location.state?.subdomain;
  const fullSubdomain = `${subdomain}.ads-txt.in`;
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(fullSubdomain);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  useEffect(() => {
    const sendEmail = async () => {
      try {
        const response = await axios.post(
          "https://ads-txt.in/api/sendEmail",
          {
            recipientEmail: "sonigaurav6030@gmail.com",
            appName: fullSubdomain,
          }
        );
        console.log("Email sent:", response.data);
      } catch (error) {
        console.error("Error sending email:", error);
      }
    };

    if (currentStep === 4) {
      sendEmail();
    }
  }, [currentStep, fullSubdomain]);

  if (currentStep !== 4) {
    return <Navigate to="/" />;
  }

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      <Link className="btn btn-light my-3" to="/accountdashboard">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: ".75rem",
            marginBottom: "1.75rem",
          }}
        >
          <IoChevronBackCircleSharp size={30} />
          <span style={{ marginRight: "10px" }}>Back To Dashboard</span>
        </div>
      </Link>
      <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">Congratulations</h1>
      <div className="flex justify-around mt-[10%] w-[88%]">
        <h2 className="text-2xl font-bold mb-4 ml-[16.5%]">
          Your Site is Live 🚀
        </h2>
      </div>
      <div className="flex justify-around mt-[10%] w-[88%]">
        <div className="copy-container">
          <div className="copy-text">
            <input
              type="text"
              className="text"
              value={fullSubdomain}
              readOnly
            />
            <button onClick={handleCopy}>
              <span className="material-icons copy-icon">content_copy</span>
            </button>
          </div>
          {isCopied && (
            <div className="tooltip">
              <span className="tooltiptext">Copied!</span>
            </div>
          )}
        </div>
      </div>
      {currentStep !== 4 && (
        <Alert severity="warning">
          Please complete the previous steps first.
        </Alert>
      )}
    </div>
  );
};

export default Final;
