import React, { useState, useEffect } from "react";
import axios from "axios";
import CreateTextFile from "../CreateTextFile/CreateTextFile";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useStep } from "../../StepContext";
import Loading from "../Loading/Loading";
import Alert from "@mui/material/Alert";
import { IoChevronBackCircleSharp } from "react-icons/io5";

const AddAppAdsTxt = ({ onBack, fileContent }) => {
  const { currentStep, goToNextStep } = useStep();
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [loading, setLoading] = useState(false); // State for loading indicator
  const location = useLocation();
  const subdomain = location.state?.subdomain;
  const navigate = useNavigate();

  useEffect(() => {
    fetchFiles();
  }, []);

  useEffect(() => {
    // Set the default selected file if there is a fileContent prop
    if (fileContent || selectedFile) {
      setSelectedFile(fileContent || selectedFile);
    }
  }, [fileContent, selectedFile]);

  useEffect(() => {
    // Retrieve last selected file from localStorage
    const lastSelectedFile = localStorage.getItem("lastSelectedFile");
    if (lastSelectedFile) {
      setSelectedFile(lastSelectedFile);
    }
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get(`https://ads-txt.in/api/files`);
      setFiles(response.data);
    } catch (error) {
      console.error("Error fetching files:", error);
    }
  };

  const handleCreateFileSuccess = (newFile) => {
    setShowCreateModal(true);
    fetchFiles();
  };

  const handleNextStep = async () => {
    try {
      setLoading(true);
      // Store the selected file in localStorage
      localStorage.setItem("lastSelectedFile", selectedFile);

      const myRes = await axios.post("https://ads-txt.in/api/getfile", {
        fileName: selectedFile
      });

      console.log("My Res FileName:", myRes);
      console.log("!myres---", !myRes.data);
      console.log("myRes,lenth : ", myRes.data.length);
      if (myRes.data.length !== 0) {
        // Store the selected file for the subdomain in the database
        console.log("File found for subdomain:", subdomain);

        await axios.post("https://ads-txt.in/api/associate-file", {
          subdomain,
          fileName: selectedFile
        });

        await axios.post("https://ads-txt.in/api/create-dns-record", {
          subdomain: subdomain
        });

        goToNextStep();
        navigate("/final", {
          state: {
            subdomain,
            selectedFile
          }
        });
      } else {
        console.log("File not found for subdomain:", subdomain);
        console.log("Attempting to create a new file for subdomain...");

        const newRes = await axios.post(
          "https://ads-txt.in/api/get-file",
          {
            fileName: selectedFile
          }
        );

        console.log("New Response:", newRes);
        console.log("New Subdomain:", subdomain);
        const newContent = newRes.data.content;

        const originalFileName = selectedFile.split(".")[0];
        console.log("Original File Name:", originalFileName);

        // Create new file and associate it with the subdomain
        await axios.post("https://ads-txt.in/api/create-file", {
          subdomain: subdomain,
          fileName: originalFileName,
          content: newContent
        });

        await axios.post("https://ads-txt.in/api/associate-file", {
          subdomain: subdomain,
          fileName: originalFileName
        });

        await axios.post("https://ads-txt.in/api/create-dns-record", {
          subdomain: subdomain
        });
        await axios.post("https://ads-txt.in/api/create-default-files", {
          subdomains: [subdomain]
        });

        goToNextStep();
        navigate("/final", {
          state: {
            subdomain: subdomain,
            selectedFile: originalFileName
          }
        });
      }
    } catch (error) {
      console.error("Error associating file with subdomain:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateDropdownAfterDelete = (deletedFileName) => {
    const updatedFiles = files.filter(
      (file) => file.fileName !== deletedFileName
    );
    setFiles(updatedFiles);

    if (selectedFile === deletedFileName) {
      setSelectedFile("");
    }
  };

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      <Link className="btn btn-light my-3" to="/accountdashboard">
        <div style={{ display: "flex", alignItems: "center", marginLeft: ".75rem", marginBottom: "1.75rem" }}>
          <IoChevronBackCircleSharp size={30} />
          <span style={{ marginRight: "10px" }}>Back to Dashboard</span>
        </div>
      </Link>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
            zIndex: 1000
          }}
        >
          <Loading />
        </div>
      ) : (
        <>
          <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">
            Add APP-ADS.TXT Records
          </h1>

          {showCreateModal && (
            <CreateTextFile
              onClose={() => setShowCreateModal(false)}
              onCreateSuccess={handleCreateFileSuccess}
              subdomain={subdomain}
              updateDropdownAfterDelete={updateDropdownAfterDelete}
            />
          )}

          <div className="flex justify-around mt-[10%] w-[88%]">
            <div className="bg-gray-700 text-white rounded px-6 py-3">
              <button type="button" onClick={() => setShowCreateModal(true)}>
                Manage
              </button>
            </div>
          </div>

          <div className="flex justify-around mt-[10%] w-[88%]">
            <select
              id="fileDropdown"
              value={selectedFile}
              onChange={(e) => setSelectedFile(e.target.value)}
            >
              <option value="">Select file...</option>
              {files.map((file, index) => (
                <option key={index} value={file.fileName}>
                  {file.fileName}
                </option>
              ))}
            </select>
          </div>

          <div className="flex justify-around mt-[10%] w-[88%]">
            <div className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
              <button type="button" onClick={handleNextStep}>
                Next Step
              </button>
            </div>
          </div>
        </>
      )}
      {/* {currentStep !== 3 && (
        <Alert severity="warning">
          Please complete the current step first.
        </Alert>
      )} */}
    </div>
  );
};

export default AddAppAdsTxt;
