import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Typography,
  Button,
  Dialog,
  DialogBody
} from "@material-tailwind/react";
import { MdDelete, MdSearch } from "react-icons/md";
import ConfirmDelete from "../ConfirmDelete/ConfirmDelete";
import { IoChevronBackCircleSharp } from "react-icons/io5";
import axios from "axios"; // Import axios for API calls

import "./AdminPanel.css";

const AdminPanel = () => {
  const location = useLocation();
  const { developerRecords } = location.state;

  console.log("Developer Records in AdminPanel:", developerRecords);

  const [showConfirmDeleteModal, setshowConfirmDeleteModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  const handleAppAdsTxtClick = (event) => {
    event.preventDefault();
    const newWindow = window.open(
      event.target.href,
      "_blank",
      "noopener,noreferrer"
    );
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const handleDeleteButtonClick = async (record) => {
    console.log("Selected Record for Delete:", record);

    try {
      // Fetch the dnsRecordId from the backend API
      const response = await axios.get(`https://ads-txt.in/api/get-dns-record-id/${record.subdomain}`);
      const { dnsRecordId } = response.data;
      
      // Check if dnsRecordId is available
      if (dnsRecordId) {
        // Include dnsRecordId in the selectedRecord object
        setSelectedRecord({ ...record, dnsRecordId });
        setshowConfirmDeleteModal(true);
      } else {
        console.error("DNS Record Id not found for subdomain:", record.subdomain);
        // Handle the case where dnsRecordId is not available
      }

      
    } catch (error) {
      console.error("Error fetching dnsRecordId:", error);
      // Handle the error as needed
    }
  };

  let recordsArray = Array.isArray(developerRecords) ? developerRecords : [developerRecords];

  // Filter the records based on the search query
  const filteredRecords = recordsArray.filter((record) =>
    record.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg mt-5 admin-table-container">
       <Link className="btn btn-light my-3" to="/accountdashboard">
       <div style={{ display: "flex", alignItems: "center" }}>
            <IoChevronBackCircleSharp size={30} />
            <span style={{ marginRight: "10px" }}>Go Back</span>
        </div>
      </Link>
      <div className="flex justify-center items-center mb-4">
        <div className="ml-4 mt-4">
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search by title..."
            className="border border-gray-300 rounded-md py-2 px-3 focus:outline-none focus:ring focus:border-blue-300 mr-2"
          />
        </div>
      </div>

      {filteredRecords.length > 0 ? (
        <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th
                scope="col"
                className="px-6 py-3 text-start font-bold text-xl font-medium text-gray-100 uppercase"
              >
                App Details
              </th>
              <th
                scope="col"
                className="px-6 py-3 text-start font-bold text-xl font-medium text-gray-100 uppercase"
              >
                Links
              </th>
              <th scope="col" className="px-6 py-3 text-start font-bold text-xl font-medium text-gray-100 uppercase">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredRecords.map((record, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-800">
                  <div className="flex items-center space-x-4">
                    <img
                      src={record.icon || "https://example.com/default-icon.png"}
                      alt="App Icon"
                      style={{ width: 80, height: 80 }}
                    />
                    <div className="app-details">
                      <h1 className="text-2xl font-bold">{record.title || "Unknown Title"}</h1>
                      <div className="font-bold mb-2">{record.developer || "App is not live"}</div>
                      <div>
                        Max Installs:{" "}
                        <span className="font-bold">{record.maxInstalls || "Unknown Max Installs"}</span>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-800">
                  <div className="flex flex-col">
                    <div className="web-details">
                      <div className="font-bold mb-2 text-lg">
                        <a
                          href={`https://${record.subdomain}.ads-txt.in/`}
                          target="__blank"
                          onClick={handleAppAdsTxtClick}
                        >
                          Website
                        </a>
                      </div>
                      <div className="text-lg">
                        <a
                          href={`https://${record.subdomain}.ads-txt.in/app-ads.txt`}
                          target="__blank"
                          onClick={handleAppAdsTxtClick}
                        >
                          App-Ads.txt
                        </a>
                      </div>
                    </div>
                  </div>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-800 dark:text-gray-800">
                  <Button
                    className="delete-button"
                    onClick={() => handleDeleteButtonClick(record)}
                    variant="gradient"
                  >
                    <MdDelete />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <Typography tag="p" className="text-lg font-bold mt-4">
          No Record Found For This Title.
        </Typography>
      )}

      {showConfirmDeleteModal && (
        <ConfirmDelete
          record={selectedRecord}
          onClose={() => setshowConfirmDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default AdminPanel;
