import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import sidebarImage from "../../assets/images/bg-sidebar-desktop.svg";
import Loading from "../Loading/Loading";

import "./Login.css";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true); // State for loading
  const navigate = useNavigate();

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after a delay (e.g., 2000 milliseconds)
    }, 2000); // Adjust the delay time as needed

    return () => clearTimeout(timeout); // Clean up timeout to avoid memory leaks
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (username === "admin" && password === "admin") {
      navigate("/accountdashboard", { state: { username } });
    } else {
      alert("Invalid username and password");
    }
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <div className="login-container">
      <div className="hidden md:block md:w-[50%] lg:w-[40%] xl:w-[30%]">
        <img
          src={sidebarImage}
          className="w-[100%] h-[82%] object-cover"
          alt="Sidebar Image"
        />
      </div>
      <div className="w-full md:w-[50%] lg:w-[60%] xl:w-[70%] flex justify-center items-center">
        <div className="sm:w-full sm:max-w-sm">
          <div className="mb-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <h2 className="text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                Sign in to your account
              </h2>
              <div>
                <label
                  htmlFor="uname"
                  className="block text-xl font-medium leading-6 text-gray-900"
                >
                  Username
                </label>
                <div className="mt-2">
                  <input
                    id="uname"
                    name="uname"
                    type="text"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <div className="flex items-center justify-between">
                  <label
                    htmlFor="password"
                    className="block text-xl font-medium leading-6 text-gray-900"
                  >
                    Password
                  </label>
                </div>
                <div className="mt-2">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    value={password}
                    autoComplete="current-password"
                    onChange={(e) => setPassword(e.target.value)}
                    required
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
