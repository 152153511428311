import React, { useState } from "react";
import axios from "axios";
import Alert from "@mui/material/Alert";
import "./DeveloperTxtFile.css";

const DeveloperTxtFile = ({ onClose, fileContent, subdomain }) => {
  const [updatedFileContent, setUpdatedFileContent] = useState(fileContent);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");

  const handleUpdateFile = async () => {
    try {
      const response = await axios.post(
        "https://ads-txt.in/api/update-file-content",
        { subdomain, updatedFileContent }
      );
      if (response.data.success) {
        setAlertMessage("File content updated successfully!");
        setAlertSeverity("success");
      } else {
        setAlertMessage("Failed to update file content.");
        setAlertSeverity("error");
      }
    } catch (error) {
      setAlertMessage("Error updating file content.");
      setAlertSeverity("error");
      console.error("Error updating file:", error);
    }
  };

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  return (
    <div className="overlay">
      <div className="box-container">
        <div className="sub-box-container">
          <div className="close-button-container">
            <div className="close-button" onClick={onClose}>
              <span className="material-icons close-icon">close</span>
            </div>
          </div>
          <div className="update-form">
            <textarea
              placeholder="File Content"
              value={updatedFileContent}
              onChange={(e) => setUpdatedFileContent(e.target.value)}
              required
            />
            <button
              type="button"
              onClick={handleUpdateFile}
              className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800"
            >
              Update File
            </button>
            <button
              type="button"
              onClick={onClose}
              className="text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center mb-2 dark:border-gray-600 dark:text-gray-400 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-800"
            >
              Close
            </button>
          </div>
          {alertMessage && (
            <Alert
              variant="filled"
              severity={alertSeverity}
              onClose={handleCloseAlert}
            >
              {alertMessage}
            </Alert>
          )}
        </div>
      </div>
    </div>
  );
};

export default DeveloperTxtFile;
