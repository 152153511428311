import React, { useEffect, useState } from "react";
import axios from 'axios';
const DisplayTextFile = ({appDetail}) => {
  const [data, setData] = useState(null);
  useEffect(()=>{
    document.title = appDetail.title || "React App";
    document.querySelector('link[rel="icon"]').href = appDetail.icon ||  "%PUBLIC_URL%/favicon.ico";
  }, [appDetail]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUrl = window.location.href;

        const parsedUrl = new URL(currentUrl);

        const hostname = parsedUrl.hostname;

        const subdomain = hostname.split(".")[0];

        console.log(subdomain);
        const response = await fetch(
          `https://${subdomain}.ads-txt.in/app-ads.txt`
        );
        const res = await axios.post(
          `https://${subdomain}.ads-txt.in/check`,{
            recipientEmail: 'sonigaurav6030@gmail.com', 
            appName: subdomain, 
          }
        );
        console.log(response, "response");
        console.log(res, "res");
        const result = await response.text();
        console.log("Result:", result);
        setData(result.fileContent);
        console.log("Data:", data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <h1 style={{ color: "#000", whiteSpace: "pre-wrap" }}>{data}</h1>
    </div>
  );
};

export default DisplayTextFile;