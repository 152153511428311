// StepContext.js

import React, { createContext, useContext, useState } from "react";

const StepContext = createContext();

export const StepProvider = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]);

  const goToNextStep = () => {
    setCompletedSteps((prevSteps) => [...prevSteps, currentStep]);
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const resetSteps = () => {
    setCurrentStep(1);
    setCompletedSteps([]);
  };

  return (
    <StepContext.Provider
      value={{ currentStep, goToNextStep, completedSteps, resetSteps }}
    >
      {children}
    </StepContext.Provider>
  );
};

export const useStep = () => useContext(StepContext);
