import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useStep } from "../../StepContext";
import Loading from "../Loading/Loading";
import "./AddApp.css";

const AddApp = () => {
  const { currentStep, goToNextStep, resetSteps } = useStep();
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
 
  useEffect(() => {
    resetSteps(); // Reset steps when AddApp component mounts
  }, []);

  const initialValues = {
    link: ""
  };

  const validationSchema = Yup.object({
    link: Yup.string().url("Invalid URL").required("Link is required")
  });

  const handleCloseAlert = () => {
    setAlertMessage("");
    setAlertSeverity("");
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const sublink = extractSublink(values.link);
    if (!sublink) {
      setSubmitting(false);
      return;
    }

    try {
      const response = await axios.get(
        `https://ads-txt.in/api/link/${sublink}`
      );

      if (response.data.exists) {
        setAlertMessage("Link already exists. Please choose another link.");
        setAlertSeverity("error");
        setSubmitting(false);
        return;
      }

      navigate("/addsubdomain", { state: { sublink } });
      goToNextStep();
    } catch (error) {
      console.error("Error checking link:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setLoading(false);
  }, [currentStep]);

  const extractSublink = (link) => {
    try {
      const url = new URL(link);

      if (url.hostname !== "play.google.com") {
        setAlertMessage(
          "Invalid domain. The link should be from play.google.com"
        );
        setAlertSeverity("error");
        return null;
      }

      const idParam = url.searchParams.get("id");

      if (idParam.includes("+")) {
        setAlertMessage("Invalid link format. Link should not contain '+' character.");
        setAlertSeverity("error");
        return null;
      }
  
      if (!idParam || !idParam.includes(".")) {
        setAlertMessage('Invalid link format. Missing or invalid "id" parameter.');
        setAlertSeverity("error");
        return null;
      }
  
      const extractedSublink = idParam.split("&")[0];
      return extractedSublink;
    } catch (error) {
      console.error("Invalid URL:", error.message);
      setAlertMessage(
        "Invalid URL. Please enter a valid Google Play Marketplace link."
      );
      setAlertSeverity("error");
      return null;
    }
  };

  return (
    <div className="w-2/3 h-full mx-auto pt-[5%]">
      {loading && <Loading />}
      {currentStep === 1 && (
        <>
          <h1 className="text-3xl font-bold mb-4 ml-[16.5%]">
            Validate Your First App
          </h1>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ dirty, isValid }) => (
              <Form className="flex flex-col items-center">
                <div className="mt-8 mb-8 w-full sm:w-2/3">
                  <label
                    htmlFor="link"
                    className={`block text-sm font-medium ${
                      window.innerWidth <= 615 ? "text-black" : "text-gray-700"
                    }`}
                  >
                    Enter the URL of one of your apps in the Google Play
                    Marketplace
                  </label>
                  <Field
                    type="text"
                    id="link"
                    name="link"
                    className="mt-1 p-3 border rounded w-full focus:outline-none focus:ring focus:border-blue-300"
                    placeholder="e.g. https://play.google.com/store/apps/details?id=com.example.app"
                  />
                  <ErrorMessage
                    name="link"
                    component="div"
                    className="text-red-500 text-sm mt-1"
                  />
                </div>
                {alertMessage && (
                  <Alert
                    variant="filled"
                    severity={alertSeverity}
                    onClose={handleCloseAlert}
                  >
                    {alertMessage}
                  </Alert>
                )}
                <div className="flex justify-around mt-[6%] w-[105%]">
                  <div className="bg-transparent text-white px-6 py-3 rounded"></div>

                  <div className="text-blue-700 hover:text-white border border-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:hover:bg-blue-500 dark:focus:ring-blue-800">
                    <button type="submit">Next Step</button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </>
      )}
      {currentStep !== 1 && (
        <Alert severity="warning">
          Please complete the current step first.
        </Alert>
      )}
    </div>
  );
};

export default AddApp;